<script setup lang="ts">
import type { Block } from 'types/index';

const HeroBlock = resolveComponent('LazyHeroBlock');
const RichTextEditorBlock = resolveComponent('LazyRichTextEditorBlock');
const TextWithImageBlock = resolveComponent('LazyTextWithImageBlock');
const SpotDynamicBlock = resolveComponent('LazySpotDynamicBlock');
const AccordionBlock = resolveComponent('LazyAccordionBlock');
const VideoBlock = resolveComponent('LazyVideoBlock');
const ImageBlock = resolveComponent('LazyImageBlock');
const QuoteBlock = resolveComponent('LazyQuoteBlock');
const TwoColumnTextBlock = resolveComponent('LazyTwoColumnTextBlock');
const EmployeeBannerBlock = resolveComponent('LazyEmployeeBannerBlock');
const FormBlock = resolveComponent('LazyFormBlock');
const SpotManualBlock = resolveComponent('LazySpotManualBlock');

const props = defineProps<{
  block: Block;
}>();

const viewTemplate = computed(() => {
  let view = null;

  if (!props.block.alias) {
    return view;
  }

  const alias = props.block.alias ?? '';

  switch (alias) {
    case 'novicellBlockGridHero':
      view = HeroBlock;
      break;

    case 'novicellBlockGridRichText':
      view = RichTextEditorBlock;
      break;

    case 'novicellBlockGridTextWithImage':
      view = TextWithImageBlock;
      break;

    case 'novicellBlockGridSpotDynamic':
      view = SpotDynamicBlock;
      break;

    case 'novicellBlockGridAccordion':
      view = AccordionBlock;
      break;

    case 'novicellBlockGridVideo':
      view = VideoBlock;
      break;

    case 'novicellBlockGridImage':
      view = ImageBlock;
      break;

    case 'novicellBlockGridQuote':
      view = QuoteBlock;
      break;

    case 'novicellBlockGridTwoColumnText':
      view = TwoColumnTextBlock;
      break;

    case 'novicellBlockGridEmployeeBanner':
      view = EmployeeBannerBlock;
      break;

    case 'novicellBlockGridFormPicker':
      view = FormBlock;
      break;

    case 'novicellBlockGridSpotManual':
      view = SpotManualBlock;
      break;

    default:
      break;
  }

  return view;
});

const rowSpacingTop = computed(() => {
  let view = null;

  if (!props.block?.settings?.content?.rowSpacingTop) {
    return '';
  }

  const margin = props.block.settings.content?.rowSpacingTop;

  switch (margin) {
    case 'None':
      view = 'no-margin-top';
      break;
    case 'Default':
      view = '';
      break;
    case 'Large':
      view = 'large-margin-top';
      break;
    default:
      view = '';
      break;
  }

  return view;
});

const rowSpacingBottom = computed(() => {
  let view = null;

  if (!props.block.settings?.content?.rowSpacingBottom) {
    return '';
  }

  const margin = props.block.settings?.content?.rowSpacingBottom;

  switch (margin) {
    case 'None':
      view = 'no-margin-bottom';
      break;
    case 'Default':
      view = '';
      break;
    case 'Large':
      view = 'large-margin-bottom';
      break;
    default:
      view = '';
      break;
  }

  return view;
});

const sectionPaddingTop = computed(() => {
  let view = null;

  if (!props.block.settings?.content?.sectionPaddingTop) {
    return '';
  }

  const padding = props.block.settings.content.sectionPaddingTop;

  switch (padding) {
    case 'None':
      view = 'no-padding-top';
      break;
    case 'Default':
      view = '';
      break;
    case 'Large':
      view = 'large-padding-top';
      break;
    default:
      view = '';
      break;
  }

  return view;
});

const sectionPaddingBottom = computed(() => {
  let view = null;

  if (!props.block.settings?.content?.sectionPaddingBottom) {
    return '';
  }

  const padding = props.block.settings.content.sectionPaddingBottom;

  switch (padding) {
    case 'None':
      view = 'no-padding-bottom';
      break;
    case 'Default':
      view = '';
      break;
    case 'Large':
      view = 'large-padding-bottom';
      break;
    default:
      view = '';
      break;
  }

  return view;
});

const bgColor = computed(() => {
  let view = null;

  if (!props.block.settings?.content?.brandThemePicker) {
    return 'bg-white';
  }

  const color = props.block.settings.content.brandThemePicker.label;

  switch (color) {
    case 'theme-primary':
      view = 'bg-primary text-white';
      break;

    case 'theme-secondary':
      view = 'bg-secondary text-white';
      break;

    case 'theme-tertiary':
      view = 'bg-tertiary text-white';
      break;

    case 'theme-quaternary':
      view = 'bg-quaternary text-white';
      break;

    case 'theme-quinary':
      view = 'bg-quinary text-white';
      break;

    case 'theme-black':
      view = 'bg-black text-white';
      break;

    case 'theme-white':
      view = 'bg-white';
      break;

    case 'theme-gray-10':
      view = 'bg-gray-10';
      break;

    case 'theme-gray-20':
      view = 'bg-gray-20';
      break;

    case 'theme-gray-30':
      view = 'bg-gray-30';
      break;

    default:
      view = '';
      break;
  }

  return view;
});

const componentStyles = computed(() => {
  const componentStyleObject = {
    rowSpacingTop: rowSpacingTop.value,
    rowSpacingBottom: rowSpacingBottom.value,
    sectionPaddingTop: sectionPaddingTop.value,
    sectionPaddingBottom: sectionPaddingBottom.value,
    bgColor: bgColor.value
  };

  return componentStyleObject;
});
</script>

<template>
  <Component
    :is="viewTemplate"
    v-if="viewTemplate"
    :data="props.block.content"
    :component-styles="componentStyles"
  />
</template>
